import t from '@jetshop/intl';
import React from 'react';
import { styled } from 'linaria/react';
import Button from '@jetshop/ui/Button';
import { theme } from './Theme';

const ErrorContainer = styled('div')`
  max-width: 80rem;
  display: flex;
  flex-direction: row;
  margin: auto;
  padding: 50px 20px;

  ${theme.below.lg} {
    display: block;
    > div {
      margin-top: 1rem;
    }
  }

  img {
    width: 100%;
  }

  .col {
    width: 100%;
  }

  a {
    background: ${theme.colors.blue};
    color: white;
    display: block;
    max-width: 140px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    margin: 20px auto;
  }

  h3,
  h5 {
    text-align: center;
    margin: 0;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 1.2;
    font-weight: bolder;
    text-transform: uppercase;
  }

  h5 {
    font-weight: 400;
    font-size: 1rem;
  }
`;

class NotFoundPage extends React.PureComponent {
  render() {
    return (
      <ErrorContainer>
        <div className="col image">
          <img
            src="https://torshov.jetshop.se/pub_docs/files/404.gif"
            alt="404 - Sorry"
          />
        </div>
        <div className="col text">
          <h3>{t('404')}</h3>
          <h3>{t('Fant ikke siden')}</h3>
          <h5>{t('Ooops!')}</h5>
          <h5>{t('Siden du leter etter eksisterer ikke lenger')}</h5>
          <Button href="/" anchor={true}>
            {t('Gå til forsiden')}
          </Button>
        </div>
      </ErrorContainer>
    );
  }
}

export default NotFoundPage;
